import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select } from './Select'
import Products from './Products/Products'
import CartButton from '../components/CartButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyle = makeStyles({
    browse: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    browseBy: {
        paddingRight: '1rem',
    },
    topContainer: props => ({
        display: 'flex',
        flexDirection: props.flexDirection,
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
    title: {
        color: '#cc584a',
        marginBottom: '2rem',
    },

    returnButton: {
        border: 'none',
        background: 'none',
    },
    returnText: {
        '&:hover': {
            filter: 'brightness(150%)',
        },
    },
    cartNavigation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    shopByLink: {
        fontFamily: 'IBM Plex Serif, monospace',
        fontWeight: '700',
        fontSize: '1.3rem',
        textAlign : "center",
        marginBottom : "2rem",
        cursor: 'pointer',
    },
    shopByLinkAnimation: {
        '&:hover': {
            filter: 'brightness(80%)',
            transitionDuration: '100ms',
            textDecoration: 'underline',
        },
    },
})

export const ProductPage = () => {
    let props
    const desktopMatches = useMediaQuery('(min-width: 700px)')
    const phoneMatches = useMediaQuery('(min-width: 600px')
    props = { flexDirection: 'row' }
    const classes = useStyle(props)
    const [category, setCategory] = useState('')
    const [superCategory, setSuperCategory] = useState('product')

    const handleChange = e => {
        setCategory(e.target.value)
    }
    const handleSuperCategoryChange = (name) => e => {
      setSuperCategory(name)
      setCategory('')
    }

    const superCategories = [
        { name: 'Product', value: 'product' },
        { name: 'Fandom', value: 'fandom' },
    ]
    const subCategories = {
        product: [
            { name : 'All Products', value : ''},
            { name: 'Prints', value: 'print' },
            { name: 'Stickers', value: 'sticker' },
            { name: 'Keychains', value: 'keychain' },
        ],
        fandom: [
            {name : 'All Fandoms', value : ''},
            { name: "JoJo Bizarre's Adventure", value: 'jojo' },
            { name: "Devil May Cry", value: 'dmc' },
            { name: 'Originals', value: 'original' },
        ],
    }
    const mappingShopByFn = ({ name,value }, index) => {
        return (
            <>
                {index !== 0 && ' | '}
                <span className={classes.shopByLinkAnimation} onClick={handleSuperCategoryChange(value)}>
                    Shop by {name}
                </span>
            </>
        )
    }
    const headerCategories = superCategories.map(mappingShopByFn)

    return (
        <>
            <div className={classes.shopByLink}>{headerCategories}</div>
            <div style={{textAlign : "center"}}>Shop currently under hiatus!</div>
            {/* <div className={classes.titleContainer}>
                {!desktopMatches && <CartButton />}
            </div>
            <div className={classes.topContainer}>
                <div className={classes.browse}>
                    {phoneMatches && (
                        <div className={classes.browseBy}>
                            <label htmlFor="select-collection">Browse by</label>
                        </div>
                    )}

                    <Select
                        data={subCategories[superCategory]}
                        onChange={handleChange}
                        value={category}
                    />
                </div>
                {desktopMatches && <CartButton />}
            </div>
            <div>
                <Products collection={category} />
            </div> */}
        </>
    )
}

export default ProductPage
